.DrawerToggle {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.DrawerToggle .Button {
    margin-right: 25px;
    padding: 7px 10px;
    width: 38px;
    height: 46px;
    font-size: 22px;
    background-color: #eb268f;
    color: white;
    cursor: pointer;
    align-self: center;
}

.DrawerToggle .NarrowButton {
    margin: 0px 25px 0px 0px;
    padding: 2px 6px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    background-color: #eb268f;
    color: white;
    cursor: pointer;
    align-self: center;
}

.DrawerToggle .Button:active {
    outline: 0;
    color: #cecece;
    background-color: #eb268f;
}

@media (min-width: 1053px) {
    .DrawerToggle {
        display: none;
    }
}
