body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/*Sweet Alert 2 Styles */

.btn-swal2 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 700;
  background-image: none;
  border-radius: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  padding: 10px 30px;
  letter-spacing: 0.2px;
  border: 4px double;
  cursor: pointer;
  margin: 0px 10px;
  background-color: #eb268f;
  color: #fff;
}

.btn-swal2:hover {
  color: black;
  background-color: #e0e0e0;
  border: 4px double black;
}

.btn-swal2:focus {
  outline: 0;
}

.popup-swal2 {
  width: 550px !important;
  background: #f4f4f4 !important;
  border: 4px double #eb268f !important;
}

.title-swal2 {
  font-size: 18px !important;
  font-weight: 600;
  text-transform: none;
  margin: 20px 0px 10px 0px !important;
  display: flex;
  background: transparent;
  border: none;
  line-height: 25px;
  font-family: "Bebas Neue", sans-serif;
}

.content-swal2 {
  margin: 15px !important;
  font-size: 14px !important;
}

.image-swal2 {
  margin: 0px !important;
}

.slick-slide div:focus {
  outline: none;
}
