.Logo {
    align-self: center;
    margin: 0px 25px;
    width: 100%;
    height: 100%;
}

@media (min-width: 1053px) and (max-width: 1150px) {
    .Logo {
        align-self: center;
        margin: 0px 0px;
    }
}

.Logo a {
    display: flex;
    cursor: pointer;
}

.Logo a img{
    height: 63px;
}

a:focus{
    outline: none;
}
