.Button {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 700;
    background-image: none;
    border-radius: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    padding: 10px 30px;
    letter-spacing: 0.2px;
    border: 4px double white;
    cursor: pointer;
    margin: 0px 10px;
}

.Button:disabled {
    color: darkgrey;
    cursor: not-allowed;
    background-color: #f9f9f9;
    border: 4px double rgb(0,0,0,.4);
}

.Estandar {
    background-color: #eb268f;
    color: white;
}

.Special {
    background-color: transparent;
    display: flex;
    width: 140px;
    height: 50px;
    padding: 0;
    border:none;
}

.Special a{
    color: white;
    width: 100%;
    height: 100%;
    line-height: 45px;
    border: 4px double #e0e0e0;
}

.Button:active {
    outline: 0;
    color: #cecece;
}

.Button:focus {
    outline: 0;
}

@media (max-width: 400px) {
    .Button {
        margin: 5px 5px 5px 0px;
        width: 100%;
    }
}

@media (hover: hover) and (pointer: fine) {

    .Special:not([disabled]) a:hover{
        color:  black !important;
        background-color: #e0e0e0;
        border: 4px double black;
    }
    
    .Estandar:not([disabled]):hover {
        color:  black !important;
        background-color: #e0e0e0;
        border: 4px double black;
    }

    .Button:disabled:hover {
        background-color: #f9f9f9;
    }    
}

@media (hover: hover) and (pointer: fine) and (max-width: 400px) {
    .Button:hover {
        background-color: #eb268f;
    }
}