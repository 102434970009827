.NavigationItems {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.NavigationLeftDisabled {
    display: none;
}

.NavigationRightBar {
    margin-left: 0px;
    margin-right: 45px;
}

.NavigationList {
    margin: 0px;
    padding: 0px;
}

.ReducedBar li a {
    line-height: 15px;
}

.ReducedLogoContainer {
    min-width: 187px;
    margin: 0 15px;
    display: flex;
    justify-content: center;
}

.ReducedLogoIcon {
    top: 15px;
    background: #fbf9f9;
    min-height: 63px;
    min-width: 63px;
    border-radius: 50%;
    padding: 5px;
    position: relative;
}

.ReducedLogoIcon div {
    margin: 0;
}

@media (max-width: 1150px) {
    .NavigationRightBar {
        margin-left: 0px;
        margin-right: 0px;
    }
    .NavigationLeftBar {
        margin-left: 0px;
    }
    .FixedLogo {
        left: 0px !important;
    }
}

@media (max-width: 1042px) {
    .MoveLogo {
        align-self: center;
    }
    .ReducedLogoIcon{
        left: 25px;
    }
}

@media (max-width: 598px) {
    .MoveLogo {
        position: absolute;
        left: -1px;
        align-self: center;
    }
}

@media (max-width: 456px) {
    .MoveLogo {
        position: absolute;
        left: -25px;
        align-self: center;
    }
}

@media (max-width: 420px) {
    .MoveLogo {
        position: absolute;
        left: -95px;
        align-self: center;
    }
}

@media (max-width: 326px) {
    .MoveLogo {
        display: none;
    }
}

.FixedLogo {
    align-self: center;
    position: relative;
    left: 60px;
}
