.SocialBar{
    position: fixed;
    top: 45%;
    right: 0;
    width: 69px;
    padding: 10px 0px;
    background-color: #EB268F;
    margin-top: -95px;
    box-shadow: -1px 2px 2px rgba(0,0,0,.16);
    opacity: 0.80;
    z-index: 1000;
    height: 200px;
}

@media (max-width: 1053px) {
    .SocialBar {
        display: none;
    }
}

@media (max-height: 390px) {
    .SocialBar {
        display: none;
    }
}

.SocialNetworkContainer {
    display: flex;
    margin: 0px 20px;
    justify-content: space-between;
    flex-direction: column;

}

.SocialNetworkContainer .SNElemFacebook {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 6px;
    transition: all .2s ease-in-out;
}

.SocialNetworkContainer .SNElemWhatsapp {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 6px;
    transition: all .2s ease-in-out;
}

.SocialNetworkContainer .SNElemInstagram {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 6px;
    transition: all .2s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {

    .SocialNetworkContainer .SNElemFacebook:active {
        outline: 0;
        color: #cecece;
    }
    
    .SocialNetworkContainer .SNElemFacebook:hover {
        background-color: #3a5894;
        border-color: #3a5894;
        width: 48px;
        height: 48px;
        margin: 6px 0px;
        font-size: 33px;
        border: 2px solid black;
    }

    .SocialNetworkContainer .SNElemWhatsapp:active {
        outline: 0;
        color: #cecece;
    }

    .SocialNetworkContainer .SNElemWhatsapp:hover {
        background-color: #1AD13F;
        border-color: #1AD13F;
        width: 48px;
        height: 48px;
        margin: 6px 0px;
        font-size: 33px;
        border: 2px solid black;
    }
    
    .SocialNetworkContainer .SNElemInstagram:active {
        outline: 0;
        color: #cecece;
    }

    .SocialNetworkContainer .SNElemInstagram:hover {
        background-color: #F7B10C;
        border-color: #F7B10C;
        width: 48px;
        height: 48px;
        margin: 6px 0px;
        font-size: 33px;
        border: 2px solid black;
    }
}

a:focus{
    outline: none;
}