.NarrowHeader {
    height: 35px;
    min-height: 35px;
    transition-property: height;
    transition-duration: 0.5s;
}

.NormalHeader{
    height: 80px;
    transition-property: height;
    transition-duration: 0.5s;
}

@media (max-width: 1053px) {
    .Toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: auto;
    }
}
