.SideDrawer {
    position: fixed;
    width: 185px;
    max-width: 70%;
    height: 100%;
    left: 100vw;
    z-index: 1000;
    top: 0;
    background-color: #eb268f;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-left: 1px solid white;
}

@media (min-width: 1053px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(-100%);
}

.Close {
    transform: translateX(100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}

.Cross {
    margin-top: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
}

.Cross i {
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.SocialNetworkContainer {
    display: flex;
    margin: 0px 18px;
    border-top: 2px solid white;
    justify-content: space-between;
}

.SocialNetworkContainer .SNElemFacebook {
    margin: 10px 4px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 6px;
}


.SocialNetworkContainer .SNElemWhatsapp {
    margin: 10px 4px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 6px;
}


.SocialNetworkContainer .SNElemInstagram {
    margin: 10px 4px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 6px;
}

a:focus{
    outline: none;
}

@media (hover: hover) and (pointer: fine) {

    .Cross i:active {
        outline: 0;
        color: #cecece;
    }

    .SocialNetworkContainer .SNElemFacebook:active {
        outline: 0;
        color: #cecece;
    }
    
    .SocialNetworkContainer .SNElemFacebook:hover {
        background-color: #3a5894;
        border-color: #3a5894;
        width: 48px;
        height: 48px;
        margin: 6px 0px;
        font-size: 33px;
    }

    .SocialNetworkContainer .SNElemWhatsapp:active {
        outline: 0;
        color: #cecece;
    }

    .SocialNetworkContainer .SNElemWhatsapp:hover {
        background-color: #1AD13F;
        border-color: #1AD13F;
        width: 48px;
        height: 48px;
        margin: 6px 0px;
        font-size: 33px;
    }
    
    .SocialNetworkContainer .SNElemInstagram:active {
        outline: 0;
        color: #cecece;
    }

    .SocialNetworkContainer .SNElemInstagram:hover {
        background-color: #F7B10C;
        border-color: #F7B10C;
        width: 48px;
        height: 48px;
        margin: 6px 0px;
        font-size: 33px;
    }
}
