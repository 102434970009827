.EditionFooter {
    position: fixed;
    bottom: 0;
    min-height: 60px;
    padding: 0px;
    margin-bottom: 0;
    z-index: 900;
    box-shadow: 6px 0px 5px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #fbf9f9;
}

.ButtonContainer {
    justify-content: center;
    align-self: center;
    margin: 0px 25px;
}

@media (max-width: 1052px) {
    .ButtonContainer {
        margin: 0px 16px;
        width: 100%;
    }

    .Button{
        width: calc(50% - 20px);
        margin: 5px 10px !important;
    }
}

@media (max-width: 400px) {
    .ButtonContainer {
        margin: 0px 5px 0px 5px;
        width: 100%;
    }

    .Button{
        margin: 5px 0px 5px 0px !important;
    }
}