@media (max-width: 1053px) {
    .NavigationItem a {
        display: none !important;
    }

    .NavigationItem:after {
        content: "\f111";
        font-family: FontAwesome;
        padding: 0 5px;
        vertical-align: middle;
        font-size: 3px;
        color: #333;
    }

    .SidebarMode {
        display: flex;
        justify-content: center;
    }

    .SidebarMode a {
        color: white;
        display: inline-block;
        font-size: 13px;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        font-weight: 700;
        background: transparent;
        margin: 10px;
    }
}

Link:focus{
    outline: none;
}

.NavigationItem{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}