.BackgroundImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #eb268f;
    overflow-y: auto;
}

.Frame {
    min-width: 150px;
    border: 4px double #eb268f;
    background: #fae8f2;
    padding: 20px;
    margin: 20px;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.ImageContainer {
    display: flex;
    position: relative;
    top: -73px;
    justify-content: center;
}

.Logo {
    width: 100px;
    height: 100px;
}

.Title {
    margin: 0px;
    font-family: "Bebas Neue", "Montserrat", cursive;
    text-shadow: none;
    font-size: 55px;
    text-align: center;
    font-weight: normal;
    margin-top: -75px;
}

.SubTitle {
    margin: 0px;
    font-family: "Bebas Neue", "Montserrat", cursive;
    text-shadow: none;
    font-size: 35px;
    text-align: center;
    font-weight: normal;
    color: #eb268f;
    margin: 10px 0 20px 0;
}

.ButtonsRow {
    align-self: center;
    display: flex;
}

.Button {
    font-family: "Bebas Neue", "Montserrat", cursive;
    font-size: 18px;
    font-weight: unset;
    padding-top: 12px;
}

@media (max-width: 500px) {
    .ButtonsRow {
        width: 100%;
        margin: 10px;
        flex-direction: column;
    }

    .Button {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 290px) {
    .Title {
        font-size: 35px;
    }

    .SubTitle {
        font-size: 20px;
    }
    .Frame {
        padding: 5px;
        margin: 5px;
    }
}
